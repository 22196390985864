import styled from 'styled-components'


export const Wrapper = styled.div`
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');


`
export const Row = styled.div`
justify-content: space-between;
padding: 0px 10%;
align-items: center;
text-align : center;
margin-bottom: 10px;

}

.costum__sub {
        padding: 1vw;
    }

    

h3.booked{
font-family: 'Anton';
text-align : center;
font-style: normal;
align-items: center;
font-weight: 400;
font-size: 36px;
margin-top : 45px
line-height: 54px;
color: #E4045A;
text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

 }
.secondparagraph{    
    text-align: center;
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    color: #E4045A;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    -webkit-letter-spacing: 5px;
    -moz-letter-spacing: 5px;
    -ms-letter-spacing: 5px;
    letter-spacing: 1px;
    padding-bottom: 15px;
}
.talents__sp{
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
text-align: center;
color: #000000;
}
.talents__spp{
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 24px;
    text-align: center;
    color: #00000;
    }
.thirdparagraph{    
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 54px;
    color: #000000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 100px;
    }
.chabeb{
    
}
@media screen and (min-width : 800px){
    .secondparagraph{
        width: 735px;
        margin: auto;        
    }
   
  

}
@media screen and (max-width : 800px){
    h3.booked{
        font-size : 25px;
    }
    .secondparagraph{
        font-size : 23px;       
    }
    .talents__sp{
        font-size: 20px;      
        }
}
`
export const Container = styled.div`
display: flex;
justify-content: center;
margin: 0px 0px 70px;
a {
    display: flex;
    justify-content: center;
}
.singleImage{
    padding-left: 15px;
    width: 85%;
}
.singleImages{
    width: 95%;
}
@media screen and (max-width: 800px){
a.imgdesk {
    display: none;
}
img.doubleImage {
    width: 100%;
}
}
@media screen and (max-width: 1920px) and (min-width: 800px){
    a.imgmobile {
        display: none;
    }
    }
`
export const MiniTitleOne = styled.div`

`
export const SliderTitle = styled.div`

`
export const CarousselWrapper = styled.div`
display: flex;
width ; 100%;
justify-content: space-evenly;
-webkit-box-align: center;
align-items: center;
margin-bottom: 100px;
padding: 30px 100px;
.carousel-control-prev-icon{
    background-image: url(https://res.cloudinary.com/dkirydqcp/image/upload/v1639149447/Gromarkt/carousel-images/Web/prev-icon.png);
    justify-content: flex-start !important;
  
}
.carousel-control-next-icon{
    background-image: url(https://res.cloudinary.com/dkirydqcp/image/upload/v1639149447/Gromarkt/carousel-images/Web/next-icon.png);
    justify-content: flex-end !important;
    widh:100%;
}
@media (min-width: 900px) {
    .carousel-indicators{
        margin-bottom: -2rem;
    }
    .carousel-indicators [data-bs-target] {
        background-color: rgb(252, 32, 119);
    border-radius: 100%;
    height: 15px;
    width: 15px !important;
    }
}
`
export const Section = styled.div`
// @media screen and (max-width: 800px){
//     .alldesc {
//         display: none;
//     }
    
//     }
// @media screen and (max-width: 1920px) and (min-width: 800px){
//         .allmobile {
//             display: none;
//         }
//         }
`
export const Subsection = styled.div`
display : flex;

button {
    margin-top: 30px;
    border-radius: 20px;
    border: none;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    padding: 7px 28px;
}
.custom__talent {
    width: 50%;
    color: #fff;
    position : relative;
    align-items : center;
    justify-content : center;
}

.custom__talent__img {
    width : 50%
    align-items : center;
    justify-content : center;
    margin : auto ;
}
.custom__content{
    display: flex;
  flex-wrap: wrap;
  align-items: center;

}
.custom-title {
align-items : center;
justify-content : center;
position: absolute;
    margin-top: 3vw;
    margin-left: 12vw;

}
.custom__talents {
    width: 50%;
    color: #fff;
    position : relative;
    align-items : center;
    justify-content : center;
}

.responsive-form input {
    width: 100%;
    box-sizing: border-box;
  }
  
  @media (min-width: 600px) {
    .responsive-form input {
      width: 50%;
    }
  }

form{
    margin-left:auto;
    margin-right:auto;
    width: 965px;
    height: 450px;
    padding:30px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box; 
    overflow: hidden; 
}
.custom__title {
    text-align: center;
}

.custom__title h1 {
    font-size: 33px;
    line-height: 55px;
    font-weight: 400;
    font-family: 'Anton';
    margin-top: 40px;
    margin-bottom: 15px;
    color: #000000;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}
.custom__subtitle {
    width: 350px;
    margin: auto;
    padding: 30px;
    border-radius: 46px;
    background: linear-gradient(180deg, #EC8EB2 0%, #E60F63 100%);
box-shadow: 0px 0px 14px 7px rgba(0, 0, 0, 0.19);
}
@media screen and (max-width: 800px){
    display:block;

}
@media screen and (max-width: 400px){
    .custom__subtitle {
        width: 300px;
}
}
.custom__subtitle h3 {
    font-family: 'Lato';
    font-size: 29px;
    font-weight: 900;
    line-height: 40px;
}

// .custom__subtitle p span.custom__parag__s {
//     // font-family: 'Lato';
//     font-weight: 700;
//     font-size: 17px;
//     line-height: 24px;
// }

.custom__subtitle p {
    display: block;
    font-size: 17px;
    text-align: left;   
    margin-left: 20px ;
}
.custom__parag {
    display: flex;
    align-items: baseline;
}
.custom__poly {
    width: 37px;
}

.custom__image{
    display: flex;
}
@media screen and (max-width: 800px){
    .custom__talent{
    width : 100%;
} 
.custom__talent__img {
    width: 325px;
    margin: auto;
    padding: 60px 0;
}
.custom__title h1{
    font-size : 26px;
}
.custom__subtitle h3{
    font-size : 24px;
}
}
`
export const Formulaire = styled.div`
display : flex;

.custom__talent__img {
    width : 50%
    align-items : center;
    justify-content : center;
    margin : auto ;
    padding : 70px ;
}
input {
    width: 100%;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #6d6060;
}


input::placeholder {font-size: 24px;}

.responsive-form h1 {
    font-size: 40px;
    margin-bottom: 60px;
}

.responsive-form button {
    width: 300px;
    height: 60px;
    border: none;
    background: #000;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
}
input:focus-visible {
    outline: none;
}
@media screen and (max-width: 800px){
    display:block;  
}
@media screen and (max-width: 800px){
    .custom__talent{
    width : 100%;
} 
.custom__talent__img {
    width: 90%;
    margin: auto;
    padding: 60px 0;
}
.responsive-form h1{
    font-size : 33px;
}
}
@media screen and (max-width : 620px){
    .custom__talent__img {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 80%;
        padding: 60px 0;
     }     
}
@media screen and (max-width : 400px){
    .responsive-form button {
        width: 200px;
    }     
}
`
export const Column = styled.div`
h3.thirdparagraph{
    display : flex;
    align-items : center;
    justify-content : center;
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    margin-top : 70px;
    color: #E60F63;
    text-align: center;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    
}
@media screen and (max-width : 800px){
    h3.thirdparagraph{
        font-size : 33px;
    }
}
     
`
export const Parall = styled.nav`
.text-box {
    text-align: center;
    padding: 3rem 1rem;
    text-align: justify;
  }
  .content span.img-txt {
  font-family: 'Anton';
font-style: normal;
font-weight: 400;
font-size: 33px;
text-transform: uppercase;
background: linear-gradient(180deg, #D70E5D 0%, #EC8EB2 52.86%, #FFFFFF 100%, #E94391 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
margin: 0 30px;
margin-top: 37vw;
}
  
  .image .content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }
//   .imagee {
//     min-height: 100vh;
//     position: relative;
//   }
  
  @media screen and (max-width:800px){
    .content span.img-txt {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    background: linear-gradient(180deg,#D70E5D 0%,#EC8EB2 52.86%,#FFFFFF 100%,#E94391 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0 30px;
    margin-top: 80vw;
    }
  }

`