import './index.css';
import './App.css';
import BodyEn from './components/BodyEn';
import BodyFr from './components/BodyFr';
import Form from 'react-bootstrap/Form';
import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Main } from './styles';

function App() {
  const [languageEN, setLanguageEN] = useState(true); // Default to English
  const navigate = useNavigate();

  const toggleLanguage = () => {
    setLanguageEN(!languageEN);
    navigate(languageEN ? '/fr' : '/');
  };

  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;

    if (browserLanguage.startsWith('fr')) {
      setLanguageEN(false); // Set to French if browser language starts with 'fr'
    } else {
      setLanguageEN(true); // Default to English for all other languages
    }
  }, []);

  return (
    <>
      <Main>
        <Routes>
          <Route path='/' element={languageEN ? <BodyEn /> : <BodyFr />} />
          <Route path='/fr' element={<BodyFr />} />
        </Routes>
        <Form>
          <Form.Check
            onChange={toggleLanguage}
            type='switch'
            id='custom-switch'
            label={languageEN ? 'English' : 'Français'}
          />
        </Form>
      </Main>
    </>
  );
}

export default App;
