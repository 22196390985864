import React from "react";
import {
  Wrapper,
  Row,
  Container,
  Section,
  Subsection,
  Formulaire,
  Column,
 
} from "./styles";
import block3 from "../../assets/blocknewfr.png";
import blocklg from "../../assets/toulfrrrr.png";
import Carousel from "react-grid-carousel";
import polygon from "../../assets/polygon.svg";
import stars from "../../assets/stars.svg";
import finalsection from "../../assets/finalsection.webp";
import HeaderFr from "../HeaderFr";
import FooterFr from "../FooterFr";
import ReactPlayer from "react-player";
import sliderpara from "../../assets/toulfr3.png"
import sliderpc from "../../assets/sliderparapccd.png"
const index = () => {
  return (
    <Wrapper>
      <HeaderFr />
      <Container>
        <a className="imgdesk" href="https://signup.mymotheragency.com/">
          <img className="singleImage" src={sliderpc} alt="block" />
        </a>
        <a className="imgmobile" href="https://signup.mymotheragency.com/">
          <img className="doubleImage" src={sliderpara} alt="block" />
        </a>
      </Container>
      <Row>
      <div id="booker">
        <div className="costum__sub">
          <h3 className="booked">Inscription facile en 3 étapes</h3>
        </div>
        </div>
      </Row>
      <Container>
        <a className="imgdesk" href="https://signup.mymotheragency.com/">
          <img className="singleImages" src={block3} alt="block" />
        </a>
        <a className="imgmobile" href="https://signup.mymotheragency.com/">
          <img className="doubleImage" src={blocklg} alt="block" />
        </a>
      </Container>
      <Row>
        <div id="talent">
        <h3 className="secondparagraph">Ils sont My Mother agency... </h3>
        {/* <h4 className="talents__spp">Communauté de MMTalents</h4> <span><br></br></span> */}
        <span className="talents__sp">Toi aussi, rejoins notre communauté de MMTalents</span>
        </div>
      </Row>
      <Carousel cols={4}
      showDots={true}
      gap={false}
      >
        <Carousel.Item style={{ overflow: "hidden", margin: "0 0vw 15px 0px", }}>
          
          <ReactPlayer url="https://youtu.be/AoifhBoSgOQ"  controls={true}  width = '350px'/>
        
      </Carousel.Item>
      <Carousel.Item>
       
          <ReactPlayer url="https://youtu.be/IdiDqzN5T1Q" controls={true} width = '350px' />
       
      </Carousel.Item>
      <Carousel.Item>
       
          <ReactPlayer url="https://youtube.com/shorts/uwDYNVcJ0eg" controls={true} width = '350px' />
        
      </Carousel.Item>
      <Carousel.Item>
        
          <ReactPlayer url="https://youtu.be/_iWK5oDnq1g"   controls={true} width = '350px'/>
        
      </Carousel.Item>
    </Carousel>
      <Section>
        <div
         
        >
          <Column>
          <div id="abn">
            <h3 className="thirdparagraph">Sélectionnez votre abonnement</h3>
            </div>
          </Column>
          <Subsection>
            <div className="custom__talent">
              <div className="custom__title">
                <h1>TALENTS CLASSIC</h1>
                <div className="custom__subtitle">
                  <h3>35€/An</h3>
                  <div className="custom__parag">
                    <div className="custom__poly">
                      <img src={polygon} alt="Logo" />
                    </div>
                    <p>
                      <span className="custom__parag__s">
                      Large variété de castings ciné, séries, pub, mode dans le monde entier{" "}
                      </span>{" "}
                      {/* <span>
                        <br></br>
                      </span>{" "}
                      <span>
                        Accès à un large éventail de castings du monde entier.
                      </span> */}
                    </p>
                  </div>
                  <div className="custom__parag">
                    <div className="custom__poly">
                      <img src={polygon} alt="Logo" />
                    </div>
                    <p>
                      <span className="custom__parag__s">
                      Visibilité internationale de ton profil grâce à notre «casting tool» inédit </span>
                      {/* <span>
                        <br></br>
                      </span>
                      <span>Accès à de nombreux articles de mode</span> */}
                    </p>
                  </div>
                  <div className="custom__parag">
                    <div className="custom__poly">
                      <img src={polygon} alt="Logo" />
                    </div>
                    <p>
                      <span className="custom__parag__s"> un contenu dédié avec des 10aine d’articles sélectionnés pour toi </span>{" "}
                      <span>
                        <br></br>
                      </span>{" "}
                      {/* <span>
                        Devenez visible pour les directeurs de casting et les
                        marques
                      </span> */}
                    </p>
                  </div>
                  <a href="https://signup.mymotheragency.com/">
                  <button>S'abonner</button>
                  </a>
                </div>
              </div>
            </div>
            <div className="custom__talent">
              <div className="custom__title">
                <h1>TALENTS LAB</h1>
                {/* <img src={star} alt="logo" /> */}
                <div className="custom__subtitle">
                  <h3>300€/An</h3>
                  <div className="custom__parag">
                    <div className="custom__poly">
                      <img src={stars} alt="Logo" />
                    </div>
                    <p>
                      <span className="custom__parag__s">
                      Large variété de castings ciné, séries, pub, mode dans le monde entier{" "}
                      </span>
                      {/* <span>
                        <br></br>
                      </span>{" "}
                      <span>
                        Accès à un large éventail de castings du monde entier.
                      </span> */}
                    </p>
                  </div>
                  <div className="custom__parag">
                    <div className="custom__poly">
                      <img src={stars} alt="Logo" />
                    </div>
                    <p>
                      <span className="custom__parag__s">
                      Visibilité internationale de ton profil grâce à notre «casting tool» inédit
                      </span>
                      {/* <span>
                      <br></br>
                      </span>
                      <span>Accès à de nombreux articles de mode</span> */}
                    </p>
                  </div>
                  <div className="custom__parag">
                    <div className="custom__poly">
                      <img src={stars} alt="Logo" />
                    </div>
                    <p>
                      <span className="custom__parag__s"> Un contenu dédié avec des 10aine d’articles sélectionnés pour toi </span>
                      {/* <span>
                        <br></br>
                      </span>{" "}
                      <span>
                        Devenez visible pour les directeurs de casting et les
                        marques
                      </span> */}
                    </p>
                  </div>
                  <div className="custom__parag">
                    <div className="custom__poly">
                      <img src={stars} alt="Logo" />
                    </div>
                    <p>
                      <span className="custom__parag__s">
                      Coaching Talents Lab exclusif:  
                      </span>{" "}
                      <br></br>
                      <span className="custom__parag__s">
                      tous nos meilleurs podcast vidéo pour réussir tes bookings
                      avec des vrais conseils des pros
                      </span>
                    </p>
                  </div>
                  <div className="custom__parag">
                    <div className="custom__poly">
                      <img src={stars} alt="Logo" />
                    </div>
                    <p>
                      <span className="custom__parag__s">
                      Masterclass et Webinaires: 
                      </span>{" "}
                      <span>
                        <br></br>
                      </span>
                      <span className="custom__parag__s">
                      accès libre aux événements présentiels et web avec nos
                      intervenants (acteurs, mannequins, photographes, réalisateurs, directeurs de casting…)
                      </span>
                    </p>
                  </div>
                  <div className="custom__parag">
                    <div className="custom__poly">
                      <img src={stars} alt="Logo" />
                    </div>
                    <p>
                      <span className="custom__parag__s">
                      Avantages premium: 
                      </span>
                      <span>
                        <br></br>
                      </span>{" "}
                      <span className="custom__parag__s">
                      accès aux tirages au sort pour gagner des cadeaux et des avantages surprises.
                      </span>
                    </p>
                  </div>
                  <a href="https://signup.mymotheragency.com/">
                  <button>S'abonner</button>
                  </a>
                </div>
              </div>
            </div>
          </Subsection>
        </div>
      </Section>
      <Formulaire>
        
        <div className="custom__talent__img" id="contact">
          <form className="responsive-form">
            <h1>Contactez-nous</h1>
            <input type="Name" placeholder="Entrez votre nom" />
            <input type="Email" placeholder="Email" />
            <input type="Message" placeholder="message" />
            <button type="submit">Envoyer</button>
          </form>
        </div>
        <div className="custom__talent__img">
          <img src={finalsection} alt="Logo" />
        </div>
        
      </Formulaire>
      <FooterFr />
    </Wrapper>
  );
};

export default index;
