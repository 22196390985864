import React ,{useState} from 'react'
import Logo from '../../assets/logo__mma.png'
import { Wrapper, NavigationStyled , Parall } from './styles'
import slide1 from '../../assets/abnfr.png'
import slide2 from '../../assets/experiencefr.webp'
import LazyLoad from 'react-lazyload';

// import slideone from "../../assets/sliderpara.png"
// import slidetwo from "../../assets/3.webp"
  import { Parallax } from 'react-parallax';

const Header = () => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleNav = () => setIsOpen(!isOpen);
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Wrapper>
      <NavigationStyled>
        <div className="custom__header">
          <div className="logo">
            <img src={Logo} alt="Logo" />
          </div>
          <div  className={`custom__nav__mobile ${isOpen ? 'open' : ''}`}>
          <button className="nav-toggle" onClick={toggleNav} >
            <span></span>
            <span></span>
            <span></span>
          </button>
          
            <ul className="nav-items-mobile">
              <li>
                <a href="#booker">Booké en 3 étapes</a>
              </li>
              <li>
                <a href="#talent">Nos Talents</a>
              </li>
              <li>
                <a href="#abn">Abonnements</a>
              </li>
              <li>
                <a href="#contact">Contactez Nous</a>
              </li>
            </ul>
            </div>
        </div>
      </NavigationStyled>   
    <div className={`imagee ${isMobile ? 'beckground-mobile' : 'image'}`}  >
      {!isMobile && (
        <Parall>
          <a href="https://signup.mymotheragency.com/" >
         <Parallax className='imagee' blur={0} bgImage={slide1} strength={400}  > 
        </Parallax> 
        </a>
        </Parall> 
      )}
      {isMobile && (
        <a href="https://signup.mymotheragency.com/" >
        <div
          className="background-image"
          style={{ backgroundImage: `url(${slide2})`,  backgroundPosition : 'center' , backgroundRepeat : 'no-repeat' ,backgroundSize :' cover' ,minHeight: '83vh' }}
        > 
        </div>
        </a>
      )}
    </div>
    {/* <Parallax className='image' blur={0} bgImage={slide1} strength={800} > 
     </Parallax>  */}
    {/* <LazyLoad height={200} once>
    <div  className="imagee" 
    onLoad={(e) => {
      e.target.setAttribute('src', e.target.getAttribute('data-src'));
    }}>
  <style>{`
    @media screen and (max-width: 2500px) and (min-width: 800px) {
      div.imagee {
        background-image: url(${slide1});
        min-height: 130vh;
        position: relative;
        background-position : 'top center';
        background-repeat : no-repeat;
        background-size : cover ;

      }
    }

    @media screen and (max-width: 800px) {
      div.imagee {
        background-image: url(${slide2});
        min-height: 100vh;
        position: relative;
        background-position :  center ;
        background-repeat : no-repeat;
        background-size : cover ;
      }
      .image{
        display : none;
      }
    }
  `}</style>
</div>
</LazyLoad> */}
    </Wrapper>
  )
}

export default Header