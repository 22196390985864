import React from 'react'
import { Wrapper, Row, Container, Section, Subsection, Formulaire , Column } from './styles'
import block3 from '../../assets/blockneweng.png'
import Carousel from 'react-grid-carousel'
import polygon from "../../assets/polygon.svg";
import stars from "../../assets/stars.svg";
import finalsection from "../../assets/finalsection.webp";
import blocklg from "../../assets/touleng.png"
import HeaderEn from "../HeaderEn"
import FooterEn from "../FooterEn"
import ReactPlayer from "react-player";
import sliderpc from "../../assets/slideerpara.png"
import sliderpara from "../../assets/toulengg.png"

const index = () => {

  return (
    <Wrapper>
      <HeaderEn/>
      <Container>
        <a className="imgdesk" href="https://signup.mymotheragency.com/">
          <img className="singleImage" src={sliderpc} alt="block" />
        </a>
        <a className="imgmobile" href="https://signup.mymotheragency.com/">
          <img className="doubleImage" src={sliderpara} alt="block" />
        </a>
      </Container>
      <Row>
        <div className='costum__sub' id='booked'>
          <h3 className='booked'>Easy 3-step registration</h3>
        </div>
      </Row>
      <Container>
        <a  className="imgdesk" href='https://signup.mymotheragency.com/'>
          <img className='singleImages' src={block3} alt="block" />
        </a>
        <a  className='imgmobile' href='https://signup.mymotheragency.com/'>
          <img className='doubleImage' src={blocklg} alt="block" />
        </a>
      </Container>
      <Row>
        <div id='talent'>
        {/* <h3 className='secondparagraph'>Meet our talents already registered on the platform</h3> */}
        <h3 className='secondparagraph'>They are My Mother agency...</h3>
        {/* <h4 className="talents__spp">Community of MMTalents</h4> <span><br></br></span> */}
        <span className='talents__sp'>You too, join our MMTalents community</span>
        </div>
      </Row>
      <Carousel cols={4}
      showDots={true}
      gap={false}
       
      >
        <Carousel.Item style={{ overflow: "hidden", margin: "0 0vw 15px 0px", }}>
          
            <ReactPlayer url="https://youtu.be/AoifhBoSgOQ"  controls={true}  width = '350px'/>
          
        </Carousel.Item>
        <Carousel.Item>
         
            <ReactPlayer url="https://youtu.be/IdiDqzN5T1Q" controls={true} width = '350px' />
         
        </Carousel.Item>
        <Carousel.Item>
         
            <ReactPlayer url="https://youtube.com/shorts/uwDYNVcJ0eg" controls={true} width = '350px' />
          
        </Carousel.Item>
        <Carousel.Item>
          
            <ReactPlayer url="https://youtu.be/_iWK5oDnq1g"   controls={true} width = '350px'/>
          
        </Carousel.Item>
      </Carousel>
      <Section>
        <div >
        <Column>
      <div id='abn'>
      <h3 className='thirdparagraph'>Select your subscription</h3>
      </div>
      </Column>
          <Subsection>
        
            <div className="custom__talent">
              <div className="custom__title">
                <h1>TALENTS CLASSIC</h1>
                <div className="custom__subtitle">
                  <h3>35€/Year</h3>
                  <div className="custom__parag">
                    <div className="custom__poly"><img src={polygon} alt="Logo" /></div><p><span className="custom__parag__s">Wide variety of film, series, advertising and fashion castings worldwide </span> </p></div>
                  <div className="custom__parag"><div className="custom__poly"><img src={polygon} alt="Logo" /></div>
                    <p><span className="custom__parag__s">International visibility of your profile thanks to our new «casting tool»</span></p></div>
                  <div className="custom__parag"><div className="custom__poly"><img src={polygon} alt="Logo" /></div><p><span className="custom__parag__s">a dedicated content with about 10 articles selected for you</span> </p></div>
                  <a href="https://signup.mymotheragency.com/">
                  <button>Selected</button>
                  </a>
                </div>
              </div>
            </div>
            <div className="custom__talent">
            <div className="custom__title">

              <h1>TALENTS LAB</h1>
              {/* <img src={star} alt="logo" /> */}


              <div className="custom__subtitle">
                <h3>300€/Year</h3>
                <div className="custom__parag">
                  <div className="custom__poly"><img src={stars} alt="Logo" /></div><p><span className="custom__parag__s">Wide variety of movie castings, series, advertising, fashion worldwide</span> </p></div>
                <div className="custom__parag"><div className="custom__poly"><img src={stars} alt="Logo" /></div>
                  <p><span className="custom__parag__s">Wide variety of movie castings, series, advertising, fashion worldwide</span></p></div>
                <div className="custom__parag"><div className="custom__poly"><img src={stars} alt="Logo" /></div><p><span className="custom__parag__s">Dedicated content with about 10 articles selected for you</span><span><br></br></span> </p></div>
                <div className="custom__parag"><div className="custom__poly"><img src={stars} alt="Logo" /></div><p><span className="custom__parag__s"> Exclusive Coaching Talents Lab:</span> <span><br></br></span><span>all our best video podcast for successful bookings with real advice from professionals</span></p></div>
                <div className="custom__parag"><div className="custom__poly"><img src={stars} alt="Logo" /></div><p><span className="custom__parag__s">Masterclass and Webinars:</span><span><br></br></span> <span>Open access to face-to-face and web events with our speakers (actors, models, photographers, directors, casting directors, etc.)</span></p></div>
                <div className="custom__parag"><div className="custom__poly"><img src={stars} alt="Logo" /></div><p><span className="custom__parag__s">Premium advantages: </span> <span><br></br></span><span>access to random draws to win gifts and benefits surprises</span></p></div>

                <a href="https://signup.mymotheragency.com/">
                  <button>Selected</button>
                  </a>
              </div>
            </div>
          </div>

          </Subsection>
        </div>

      </Section>
      <Formulaire>
        <div className="custom__talent__img" id='contact'>
          <form className="responsive-form">
            <h1>Contact US</h1>
            <input
              type="Name"
              placeholder="Enter your name"
            />
            <input
              type="Email"
              placeholder="Email"
            />
            <input
              type="Message"
              placeholder="message"
            />
            <button type="submit">Send</button>
          </form>
        </div>
        <div className="custom__talent__img">
          <img src={finalsection} alt="Logo" />
        </div>
      </Formulaire>
      <FooterEn/>
    </Wrapper>

  )
}

export default index