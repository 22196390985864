import styled from 'styled-components'

export const Main = styled.div`
.form-switch{
    margin-bottom: .125rem;
    min-height: 1.5rem;
    padding-left: 2.5em;
    padding-right: 50px;
    justify-content: flex-end;
    display: flex;
  
}
`