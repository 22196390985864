import styled from 'styled-components'

export const Wrapper = styled.div`
margin : auto;
position : relative;
.carousel-caption {
    position: absolute;
    right: 7%;
    bottom: 400px;
    left: 8%;
    padding-top: 1.25rem;
    /* padding-bottom: 1.25rem; */
    color: #fff;
    text-align: center;
}
.image{
    width : 100%;
    height : 100%;
}
`;
export const NavigationStyled = styled.nav`
a {
    text-decoration: none;
    list-style: none;
    color: #;
}


ul.nav-items li {
    text-decoration: none;
    list-style: none;
    
}

ul.nav-items {
    display: flex;
    gap: 5vw;
}

.custom__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 130px;
    align-items: center;
    position: absolute;
    z-index: 10;
    top: 50px;
}

.custom__nav {
    justify-content: center;
}

ul.nav-items {
    margin-bottom: 0;
}

ul.nav-items li a {
    font-size: 1.1vw;
    color: #fff;
}
.custom__nav__mobile{
    display : none ;
}
ul.nav-items-mobile {
    display: none;
}


@media screen and (max-width : 1920px) and (min-width : 800px ){
    
    .custom__nav__mobile{
        display : block;
    }
    ul.nav-items-mobile {
        display: block;
    }
    .custom__nav {
        display: none;
    }  
    .logo{
        width: 50%;
       
    }
    .logo img{
        width : 30%
    }
    .nav-toggle span {
        display: block;
        width: 30px;
        height: 2px;
        background: #fff;
        margin-bottom: 5px;
        transition: transform 0.3s ease;
      }
      button.nav-toggle {
        background: transparent;
        border: none;
        position: absolute;
    right: 20px;
    top: 20px;
    z-index: 999;
    }
    ul.nav-items-mobile {
        background: rgba(0,0,0,0.5);
        position: absolute;
        right: 0;
        top: -50px;
        width: 30%;
        height: 100vh;
        padding: 100px 25px;
    }
    ul.nav-items-mobile li {
        text-decoration: none;
        list-style: none;
        padding: 10px 0;
    }
    ul.nav-items-mobile li a {
        color : #fff ;
 
    }
    .custom__header {
        padding: 0 20px !important;
    }
    .custom__nav__mobile.open ul.nav-items-mobile {
        display: none !important;
 
 
    }
    ul.nav-items li {
        text-decoration: none;
        list-style: none;
       
    }

}
@media screen and (max-width: 800px){
    
    .custom__nav__mobile{
        display : block;
    }
    ul.nav-items-mobile {
        display: block;
    }
    .custom__nav {
        display: none;
    }  
    .logo{
        width: 70%;
        margin: auto;
    }
    .logo img{
        width : 100%
    }
    .nav-toggle span {
        display: block;
        width: 30px;
        height: 2px;
        background: #fff;
        margin-bottom: 5px;
        transition: transform 0.3s ease;
      }
      button.nav-toggle {
        background: transparent;
        border: none;
        position: absolute;
    right: 20px;
    top: 20px;
    z-index: 999;
    }
    ul.nav-items-mobile {
        background: rgba(0,0,0,0.5);
        position: absolute;
        right: 0;
        top: -50px;
        width: 50%;
        height: 100vh;
        padding: 100px 25px;
    }
    ul.nav-items-mobile li {
        text-decoration: none;
        list-style: none;
        padding: 10px 0;
    }
    ul.nav-items-mobile li a {
        color : #fff ;
 
    }
    .custom__header {
        padding: 0 20px !important;
        margin-top : -40px;
    }
    .custom__nav__mobile.open ul.nav-items-mobile {
        display: none !important;
 
 
    }
    ul.nav-items li {
        text-decoration: none;
        list-style: none;
       
    }

}






`

export const Parall = styled.nav`
.text-box {
    text-align: center;
    padding: 3rem 1rem;
    text-align: justify;
  }
  .content span.img-txt {
  font-family: 'Anton';
font-style: normal;
font-weight: 400;
font-size: 33px;
text-transform: uppercase;
background: linear-gradient(180deg, #D70E5D 0%, #EC8EB2 52.86%, #FFFFFF 100%, #E94391 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
margin: 0 30px;
margin-top: 37vw;
}
  
  .image .content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }
  .imagee {
    min-height: 100vh;
    position: relative;
  }
  
  @media screen and (max-width:800px){
    .content span.img-txt {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    background: linear-gradient(180deg,#D70E5D 0%,#EC8EB2 52.86%,#FFFFFF 100%,#E94391 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0 30px;
    margin-top: 80vw;
    }
  }

`
export const Contentful = styled.div`


.content__slide h2 {
    font-size: 36px;
    text-align: center;
}

.content__slide {
    width: 500px;
    color: #fff;
}

.texte {
    width: 260px;
    margin: auto;
    text-align: center;
    margin-top: 20px;
}

span {
    font-size: 20px;
    text-align: center;
}

.content__slide {
    text-align: center;
    position: relative;
    float : right;
}

button {
    margin-top: 30px;
    border-radius: 20px;
    border: none;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    padding: 7px 28px;
}
.content__slide img {
    position: absolute;
    left: 65px;
    top: -130px;
    width : 450px;
}

`
export const Content = styled.div`
position : absolute;
.caption-title{
    font-family: 'Anton';
font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 60px;
text-transform: uppercase;

background: linear-gradient(180deg, #D70E5D 0%, #EC8EB2 52.86%, #FFFFFF 100%, #E94391 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}
.caption-parag{
    font-family: 'Anton';
font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 60px;
text-transform: uppercase;

background: linear-gradient(180deg, #D70E5D 0%, #EC8EB2 52.86%, #FFFFFF 100%, #E94391 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

`

